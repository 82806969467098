// .uk-button
@mixin hook-button {
	align-items: center;
	border-radius: $global-control-height;
	display: inline-flex;
	font-weight: $bold;
	padding: 13px 35px;
	text-align: left;
	transition: $transition;

	.icon {
		height: $font-xmedium;
		margin-left: $gutter-small;
		width: auto;

		&-arrow-right {
			transition: transform $transition;
		}
	}
}

// .uk-button:hover
@mixin hook-button-hover {

	.icon-arrow-right {
		transform: translateX(25%);
	}
}

// .uk-button-default
@mixin hook-button-default {
	border: $border-width solid $button-default-border;
}

// .uk-button-secondary
@mixin hook-button-secondary {
	border: $border-width solid transparent;
}