@mixin hook-slider-misc {

	.uk-slider-container {
		overflow: visible;

		.uk-slider-items {

			> li {
				transition: opacity $transition;

				&:not(.uk-active) {
					opacity: 0.5;
				}

				&:last-child {
					order: -1;
				}
			}

			&.uk-transition {

				> li {
					opacity: 1;
				}
			}
		}
	}
}
