@mixin hook-base-body {

	&.uk-light {
		background-color: $black;
		color: $white;

		.uk-lightbox {
			background-color: $black;

			&-items > * {
				color: $white;
			}

			&-toolbar {
				background: $black;
				color: $white;

				>* {
					color: $white;
				}

				&-icon {
					color: $white;

					&:hover {
						color: $pink;
					}
				}
			}

			&-button {
				background: $black;
				color: brand-colour($global-color, 80%);

				&:hover,
				&:focus {
					color: $white;
				}
			}
		}
	}
}

// h1, .uk-h1
@mixin hook-base-h1 {

	@media (max-width: $breakpoint-xsmall-max) {
		font-size: $global-large-font-size;
	}
}

@mixin hook-base-h5 {
	margin-bottom: $margin;

	& + p {
		margin-top: 0;
	}
}

@mixin hook-base-h6 {
	margin-bottom: $margin;

	& + p {
		margin-top: 0;
	}
}


// Custom
@mixin hook-base-misc {

	// Selection
	::selection {
		background-color: $global-color;
	}

	// Smaller fonts < 960px
	@media (max-width: $breakpoint-small-max) {

		h3,
		.uk-h3 {
			font-size: $font-large;
		}

		h4,
		.uk-h4 {
			font-size: $font-medium;
		}

		h5,
		.uk-h5 {
			font-size: $font-size;
		}

		h6,
		.uk-h6 {
			font-size: $global-font-size;
		}
	}
}
