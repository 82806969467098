// .uk-input,
// .uk-select,
// .uk-textarea
@mixin hook-form {
	border: $border-width solid $form-border;
	border-radius: $margin-small;
	font-family: $font-default;
	font-size: $font-size;
	transition: $transition;
	transition-property: color, background-color, border;
}

// .uk-form-label
@mixin hook-form-label {
	color: $form-label-color;
	font-size: $form-label-font-size;
	font-weight: $bold;
	text-transform: uppercase;
}

// .uk-form-stacked .uk-form-label
@mixin hook-form-stacked-label {
	margin-bottom: 5px;
}

// .uk-radio,
// .uk-checkbox
@mixin hook-form-radio {
	background-size: 130%;
	border: $form-radio-border-width solid $form-radio-border;
	transition: $transition;

	+span {
		display: inline-block;
		margin-left: $margin-small;
	}
}

// Checkbox Bounce Animation
@keyframes checked {
	0% {
		background-size: 0%;
	}

	50% {
		background-size: 140%;
	}

	75% {
		background-size: 110%;
	}

	100% {
		background-size: 120%;
	}
}

// Custom
@mixin hook-form-misc {

	// Checkbox animation
	.uk-checkbox {
		background-size: 120%;
		border-radius: 3px;

		// sass-lint:disable-block no-duplicate-properties
		&:focus-within {
			outline: Highlight auto 1px;
			outline: -webkit-focus-ring-color auto 1px;
		}

		&:checked {
			animation: checked $speed-slow linear;

			// Inverse
			.uk-light form & {
				background-image: url('data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2021%2021%22%20width%3D%2221%22%20height%3D%22201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20points%3D%225%2010.75%208.5%2014.25%2016%206%22%20stroke%3D%22%23d14905%22%20stroke-width%3D%222px%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20fill%3D%22none%22%3E%3C%2Fpolyline%3E%0A%3C%2Fsvg%3E');
			}
		}
	}
}