//
// Main
//

// Gutter for full width elements
@mixin gutter-full {
	@media (min-width: $breakpoint-large) {
		padding: 0 $gutter-medium;
	}
}

// Hover
@mixin hover {

	&:hover,
	&:focus,
	&:active,
	&.uk-open,
	&.uk-active {
		@content;
	}
}

// Image caption
@mixin image-caption {
	font-size: 0.8rem;
	padding: $global-small-margin;
	text-align: center;
}

// Truncate text
@mixin text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// Word break
@mixin word-break {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
}


//
// UIkit
// https://github.com/uikit/uikit/tree/master/src/scss
//
// Imports UIkit mixin overrides for the theme
// These are not used by main.scss, although loaded
//
// ========================================================================

// Base
@import 'uikit/base';

// Elements
@import 'uikit/table';
@import 'uikit/icon';
@import 'uikit/form';
@import 'uikit/button';

// Layout
@import 'uikit/card';

// Javascript
@import 'uikit/slider';

// Navs
@import 'uikit/navbar';
@import 'uikit/breadcrumb';

// Lightbox
@import 'uikit/lightbox';

// Utilities
@import 'uikit/text';