// .uk-icon-button
@mixin hook-icon-button {
	border: $border-width solid transparent;
	position: relative;
	transition: $transition;

	.icon {
		display: inline-flex;
		height: $global-font-size;
		position: relative;
		transition: opacity $transition;
		z-index: 2;
	}

	&::after {
		border-radius: 50%;
		content: '';
		height: $global-control-height;
		left: -$border-width;
		position: absolute;
		top: -$border-width;
		transform: scale(0);
		transition: $transition;
		transition-property: background-color, transform;
		width: $global-control-height;
	}

	&.play-icon {
		border-color: transparent;
		transition-duration: $speed-slow;

		.icon {
			color: $white;
			left: $border-width;
		}

		&::after {
			transition-duration: $speed-slow;
			transform: scale(1);
		}

		@media (min-width: $breakpoint-small) {
			height: $global-control-xlarge-height;
			width: $global-control-xlarge-height;

			.icon {
				height: $global-medium-font-size;
			}

			&::after {
				height: $global-control-xlarge-height;
				width: $global-control-xlarge-height;
			}
		}
	}
}
