// .uk-navbar-nav > li > a
@mixin hook-navbar-nav-item {
	transition: $transition;

	@media (min-width: $breakpoint-large) {
		font-size: $font-medium;
	}
}

@mixin hook-navbar-misc {
	.uk-navbar {
		&-right {
			margin-left: 0;
		}

		&-toggle svg {
			color: $white;
		}
	}
}