// .uk-lightbox-button
@mixin hook-lightbox-button {
	border: 2px solid transparent;
	transition: $transition;

	&:focus-visible {
		border-color: $black;
		border-radius: 5px;
	}

	@media (max-width: $breakpoint-small-max) {
		display: none;
	}
}

// .uk-lightbox-toolbar-icon
@mixin hook-lightbox-toolbar-icon {
	&.uk-close {
		border: 2px solid transparent;
		transition: $transition;
	}

	&:focus-visible {
		border-color: $black;
		border-radius: 5px;
	}
}

// Custom
@mixin hook-lightbox-misc {

	.uk-lightbox-caption {
		@include image-caption;

		> div {
			line-height: $line-height;
			margin: 0 auto;
			max-width: $breakpoint-large;
			padding: $margin-small;
		}
	}
}
