// .uk-card
@mixin hook-card {

	a.uk-position-cover {
		z-index: 3;
	}

	.icon-clock {
		margin-right: 7px;
		height: 16px;
		position: relative;
		top: -1px;
	}
}

// .uk-card-body
@mixin hook-card-body {

	>* {
		width: 100%;
	}
}

// [class*='uk-card-media']
@mixin hook-card-media {
	min-height: calc(100vw / 1.54);
	overflow: hidden;

	img {
		transition: transform ($speed * 3);
		transform-origin: left;
		transform: scale(1) translate(-50%, -50%);

		.uk-card:hover a+& {
			transform: scale(1.05) translate(-50%, -50%);
		}
	}

	@media (min-width: $breakpoint-small) {
		min-height: calc(100vw / 3.2);
	}

	@media (min-width: $breakpoint-medium) {
		min-height: calc(100vw / 6.5);
	}

	@media (min-width: $breakpoint-large) {
		min-height: calc(100vw / 6.5);
	}

	@media (min-width: $breakpoint-xlarge) {
		min-height: calc(100vw / 8.5);
	}
}

// .uk-card-title
@mixin hook-card-title {
	font-family: $font-default;
	font-weight: $bold;
	margin: 0;

	@media (min-width: $breakpoint-tablet) {
		.uk-child-width-1-2\@m:not(.uk-child-width-1-4\@l) & {
			font-size: $global-large-font-size;
		}
	}
}

// .uk-card-default
@mixin hook-card-default {

	.uk-card-body {
		padding-bottom: 0;
	}

	.play-icon {

		.icon {
			opacity: 0.75;
		}

		&::after {
			background-color: rgba($white, 0.25);
		}
	}

	.uk-grid-small & {
		transition: background-color $transition;
		padding-bottom: $margin;

		.uk-card-body {
			padding-left: $margin;
			padding-right: $margin;
		}
	}

	.uk-grid-small.uk-child-width-1-2\@m & {
		background-color: $grey-warm;
		padding-bottom: $gutter;

		.uk-card-body {
			padding-left: $gutter;
			padding-right: $gutter;
		}

		@media (min-width: $breakpoint-large) {
			padding-bottom: $gutter-medium;

			.uk-card-body {
				padding-left: $gutter-medium;
				padding-right: $gutter-medium;
			}
		}

		// Inverse
		.uk-light & {
			background-color: $grey-inverse;
		}
	}
}

// .uk-card-default.uk-card-hover:hover
@mixin hook-card-default-hover {

	.uk-grid-small & {

		// Inverse
		.uk-light & {
			background-color: $grey-inverse;
		}
	}

	.play-icon {

		.icon {
			opacity: 1;
		}

		&::after {
			background-color: $global-color;
		}
	}
}