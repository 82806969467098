@mixin hook-text-lead {

	@media (min-width: $breakpoint-small) {
		margin-bottom: ($gutter * 2);
	}

	@media (max-width: $breakpoint-xsmall-max) {
		font-size: $font-xmedium;
	}
}
